import { GetStaticProps } from 'next';
import { Router } from 'next/router';
import React, { FunctionComponent, useEffect } from 'react';

import Hero from 'components/Hero/Hero';
import GridSection from 'components/sections/GridSection/GridSection';
import { useCopyStore } from 'store';
import { CopyStoreType, ProjectProps } from 'store/copy.types';
import { queryCMS } from 'utils/cms/api';
import { indexPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';
import { pageMotionProps } from 'utils/styles/animations';

import * as S from './HomePage.styles';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const response = await queryCMS({
    query: indexPageQuery({ locale }),
  });

  const { header, footer, indexPage: page, sharedCopy } = response;
  if (!page || !header || !footer) return { notFound: true };

  const { title, head, hero, ourWork, projectsList } = page;
  const { menu: headerMenu } = header;

  const mappedProjects = projectsList.map(
    ({
      project: { id, title, tags, poster, case_study, video },
    }): ProjectProps => ({
      id,
      title,
      tags,
      case_study,
      poster: {
        url: poster.url || null,
        alternativeText: poster.alternativeText || null,
      },
      video,
    })
  );

  const {
    socialMedia,
    locations,
    name: footerName,
    menu: footerMenu,
    backgroundColor,
  } = footer;

  return {
    props: {
      initialCopy: {
        head,
        header: {
          menu: headerMenu,
        },
        footer: {
          name: footerName,
          socialMedia,
          locations,
          menu: footerMenu,
          backgroundColor,
        },
        index: {
          title,
          hero,
          ourWork,
        },
        projects: mappedProjects,
        global: sharedCopy.global,
      },
    },
    revalidate: ISR_TIMEOUT,
  };
};

interface IndexPageProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
    header: CopyStoreType['copy']['header'];
    index: CopyStoreType['copy']['index'];
    projects: CopyStoreType['copy']['projects'];
    footer: CopyStoreType['copy']['footer'];
    global: CopyStoreType['copy']['global'];
  };
  router: Router;
}

const HomePage: FunctionComponent<IndexPageProps> = ({ initialCopy }) => {
  const { copy, setCopy } = useCopyStore();

  useEffect(() => {
    setCopy(initialCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCopy]);

  if (!copy.index) return null;

  return (
    <S.HomePageWrapper {...pageMotionProps}>
      <Hero />
      <GridSection copy={copy} />
    </S.HomePageWrapper>
  );
};

export default HomePage;

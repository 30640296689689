import { motion } from 'framer-motion';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled(motion.section)`
  margin-top: -420rem;
  background: transparent;
  padding: 46rem 55rem;
  display: flex;
  flex-direction: column;
  position: relative;

  ${mediaTablet(`
    gap: 35rem;
    margin-top: -239rem;
    padding: 46rem 96rem;
    margin-bottom: 450rem;
  `)}
`;

export const ContentWrapper = styled(motion.div)`
  width: 80%;
  min-height: calc(100% - 700rem);
  position: relative;
  margin: 14vh 0 calc(100vh - 14vh - 1205rem); // margin - paddings in hero
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 600px) {
    margin: 0;
  }

  ${mediaTablet(`
    margin: 445rem 0 0;
  `)}
`;

export const Heading = styled(motion.div)`
  position: relative;
  z-index: 1;
  user-select: none;
  text-align: left;
`;

export const LinkWrapper = styled(motion.div)`
  color: ${colors.white};
  ${setTypography('bodyBoldMedium')};
  pointer-events: none;
  z-index: 1;
  user-select: none;
  text-align: center;
  margin: 0 0 0 -60rem;

  a {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    pointer-events: all;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: -35rem;
      height: 3px;
      background: ${colors.enargyYellow};
    }
  }

  ${mediaTablet(`
    text-align: left;
    margin: 0;
  `)}
`;

export const BottomBar = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  padding: 92rem 0;

  ${mediaTablet(`
    padding: 173rem 0 92rem;
  `)}
`;

export const ScrollWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 25rem;

  span {
    ${setTypography('scrollButton')};
    color: ${colors.alizarinCrimson};
  }

  svg {
    height: 39rem;
    color: ${colors.alizarinCrimson};
  }
`;

export const IconWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    max-width: 43px;
    max-height: 19px;
    color: ${colors.alizarinCrimson};
  }
`;

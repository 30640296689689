import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import Text from 'components/Text/Text';
import { useCopyStore } from 'store';
import { ReactComponent as SvgScrollDown } from 'svgs/scrollDown.svg';
import { fadeInUpMotion, opacityMotion } from 'utils/styles/animations';

import * as S from './Hero.styles';

export interface HeroProps {}

const defaultProps: Partial<HeroProps> = {};

const Hero: FunctionComponent<HeroProps> = () => {
  const { copy } = useCopyStore();

  return (
    <S.Wrapper id="hero" aria-label="hero">
      <S.ContentWrapper>
        <S.Heading
          {...fadeInUpMotion}
          transition={{ delay: 1.4, duration: 0.6 }}
        >
          <Text as="h3" text={copy.index.hero.title} size="bodyBoldBig" />
        </S.Heading>

        {copy.index.hero.link !== ' ' && (
          <S.LinkWrapper
            {...fadeInUpMotion}
            transition={{ delay: 1.4, duration: 0.6 }}
          >
            <Link href={copy.index.hero.link}>
              <a>{copy.index.hero.linkText}</a>
            </Link>
          </S.LinkWrapper>
        )}
      </S.ContentWrapper>

      <S.BottomBar>
        <S.ScrollWrapper
          aria-label={copy.index.hero.scrollText}
          {...opacityMotion}
          transition={{ delay: 1.6, duration: 0.6 }}
        >
          <SvgScrollDown />
          <span>{copy.index.hero.scrollText}</span>
        </S.ScrollWrapper>
      </S.BottomBar>
    </S.Wrapper>
  );
};

Hero.defaultProps = defaultProps;

export default Hero;

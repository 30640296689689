import { motion } from 'framer-motion';
import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

export const GridSectionWrapper = styled(motion.section)`
  padding: 546rem 55rem 52rem;

  ${mediaTablet(`
    padding: 175rem 55rem 52rem;
  `)}

  ${mediaDesktop(`
    padding: 175rem 100rem 52rem;
  `)}
`;

export const GridSectionTopBar = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const PageTitleWrapper = styled(motion.div)`
  margin: 0;
`;

export const TextWrapper = styled(motion.div)`
  padding: 0;
  text-align: left;

  display: none;

  & > p {
    grid-column: 3 / 4;
    padding: 0 50rem;
  }

  ${mediaTablet(`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 85rem 0 0 0;
  `)}

  ${mediaDesktop(`
      padding: 185rem 0 0;
  `)}
`;

export const GridWrapper = styled(motion.div)`
  padding: 175rem 0 0;
  margin: 0 0 375rem;

  ${mediaTablet(`
    padding: 87rem 0 1440rem;
    margin: 0;

    & > div {
      & > div:nth-child(4n + 2), & > div:nth-child(4n + 4) {
        & > div {
          transform: translateY(50%);
        }
      }
    }
  `)}
`;

export const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 365rem;

  ${mediaTablet(`
    margin: 0 auto 500rem;
  `)}
`;

import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import CardGrid from 'components/CardGrid/CardGrid';
import LinkButton from 'components/LinkButton/LinkButton';
import Text from 'components/Text/Text';
import { CopyStoreType } from 'store/copy.types';
import { ROUTES } from 'utils/routes';
import { fadeInUpMotion } from 'utils/styles/animations';

import * as S from './GridSection.styles';

export enum DisplayAsTypes {
  row = 'row',
  column = 'column',
}

export enum AlignTypes {
  start = 'start',
  center = 'center',
  end = 'end',
}

export interface GridSectionProps {
  copy: CopyStoreType['copy'];
}

const defaultProps: Partial<GridSectionProps> = {};

const GridSection: FunctionComponent<GridSectionProps> = ({ copy }) => {
  const router = useRouter();

  return (
    <S.GridSectionWrapper id="work">
      <S.GridSectionTopBar>
        <S.PageTitleWrapper
          aria-label={copy.index.ourWork.title}
          {...fadeInUpMotion}
          transition={{ delay: 1.5, duration: 0.6 }}
        >
          <Text as="h2" text={copy.index.ourWork.title} size="heading1" />
        </S.PageTitleWrapper>

        <S.TextWrapper
          aria-label={copy.index.ourWork.description}
          {...fadeInUpMotion}
          transition={{ delay: 1.5, duration: 0.6 }}
        >
          <Text text={copy.index.ourWork.description} size="body3" />
        </S.TextWrapper>
      </S.GridSectionTopBar>

      <S.GridWrapper role="grid">
        <CardGrid items={copy?.projects?.slice(0, 8)} />
      </S.GridWrapper>

      <S.ButtonWrapper
        {...fadeInUpMotion}
        transition={{ delay: 1.5, duration: 0.6 }}
      >
        <LinkButton
          onClick={() => router.push(ROUTES.SERVICES)}
          text={copy.index.ourWork.showMoreText}
          size="heading3"
        />
      </S.ButtonWrapper>
    </S.GridSectionWrapper>
  );
};

GridSection.defaultProps = defaultProps;

export default GridSection;
